<template>
  <div class="content-list">
    <div class="content-list-left-nav" style="margin-top: 80px">
      <!--        <span>{{paths[paths.length-1]}}{{dict[language].column}}</span>-->
      <div>
        <ul>
          <li :class="{active: true}">
            <router-link to="/">首页 / 脑卒中救治中心</router-link>
          </li>
        </ul>
      </div>
    </div>
    <el-row justify="center" type="flex">
      <el-col :span="8"><span class="item-title">脑卒中救治中心</span></el-col>
    </el-row>
    <el-row type="flex" justify="center">
      <el-col :span="10"><span class="item-title-en">Stroke Rescue Center</span></el-col>
    </el-row>
  </div>
</template>

<script>
import mixs from '@/mixs'
import dict from '@/assets/json/dictionaries.json'
import {getSwArticle, articleReadNum} from '@/http/request'

export default {
  name: "ContentList",
  props: ['id'],
  inject: ['language'],
  mixins: [mixs],
  data() {
    return {
      searchText:'',
      pageStatus: 'list',
      currentPage: 1,
      dataList: [],
      imgList: [],
      curObj: {},
      content: {},
      dict,
      total: 0,
      dataForm: {
        navId: '',
        page: 1,
        limit: 100
      }
    };
  },
  watch: {
    id(_new) {
      if (_new) {
        this.$set(this.dataForm, 'navId', _new)
      }
    },
    "dataForm.navId"() {
      this.resetData();
      this.getSwArticle();
    }
  },
  computed: {
    paths() {
      return JSON.parse(this.$route.query.paths)
    },

    menu() {
      return JSON.parse(this.$route.query.menu)
    },
    liList() {
      return this.menu.children && this.menu.children.filter(v => v.status === 1)
    }
  },

  created() {
    this.$set(this.dataForm, 'navId', this.id);
    this.$set(this.dataForm, 'lanuage', this.language === 'zh' ? 0 : 1)
    // this.getSwArticle();
  },

  methods: {
    downloadFile(url){
      window.open(url);
    },
    // 请求文章列表
    getSwArticle() {
      getSwArticle(this.dataForm).then(({data}) => {
        if (data.code === 0) {
          this.dataList = data.data.list;
          this.total = data.data.total
          this.dataForm.page++;
          /**
           * 如果只有一个值直接到详情页面
           */
          if (this.total === 1) {
            this.handlerClickList(this.dataList[0])
          }
        }
      })
    },

    resetData() {
      this.$set(this.dataForm, 'limit', 10);
      this.$set(this.dataForm, 'page', 1);
      this.pageStatus = "list";
      this.total = 0;
    },

    // 点击栏目
    handlerSwitchColumn(id) {
      this.$set(this.dataForm, 'navId', id);
      this.resetData()
    },

    // 点击文章列表
    handlerClickList(obj) {
      this.curObj = obj;
      try {
        this.content = JSON.parse(this.curObj.content);
      } catch {
        this.content = {
          type: 1,
          msg: this.curObj.content
        }
      }
      if (this.content.type == "2") {
        this.imgList = this.content.msg.split(",")
      }
      this.pageStatus = "detail";
      this.articleReadNum(obj.id)
    },

    articleReadNum(id) {
      articleReadNum(id).then(({data}) => {
        if (data.code === 0) {
          console.log("阅读累加成功")
        }
      })
    },

    handleCurrentChange(n) {
      this.$set(this.dataForm, 'page', n);
      this.getSwArticle();
    },
  }
};
</script>

<style lang='less'>
.item-title {
  margin: 20px auto;
  text-align: center;
  font-size: 28px;
  color: #404040;
  font-weight: bold;
  //margin-top: 20px;
  //margin-bottom: 20px;
}
.item-title-en{
  margin: 30px auto;
  text-align: center;
  font-size: 26px;
  color: #E7ECF2;
  font-weight: normal;
  //margin-bottom: 40px;
}

.content-list {
  width: 933px;
  margin: 0 auto;

  .query-breadcrumb {
    margin: 25px 0 70px;
  }

  .content-list-warp {
    display: flex;
    margin-bottom: 50px;

    .content-list-left-nav {
      width: 150px;

      & > span {
        display: block;
        padding-bottom: 30px;
        font-size: 15px;
        font-weight: bold;
        color: #595757;
      }

      div {
        //height: 100%;
        // border-left: solid 1px #e6e6e6;
        ul {
          li {
            padding: 10px 15px;
            font-size: 13px;
            font-weight: 400;
            color: #595757;
            line-height: 43px;
            position: relative;
            border-left: solid 1px #e6e6e6;
            cursor: pointer;

            &.active {
              font-size: 15px;
              font-weight: 500;
              color: rgba(20, 153, 220, 1);

              &::before {
                width: 5px;
                height: 30px;
                content: "";
                position: absolute;
                left: 0;
                top: 16px;
                background: rgba(20, 153, 220, 1);
              }
            }
          }
        }
      }
    }

    .content-list-main {
      flex: 1;
      padding: 0 30px 30px;

      .content-list-main-html-title {
        color: #123577;
        font-size: 25px;
        line-height: 53px;
        font-weight: bold;
        min-height: 38px;
        margin-bottom: 20px;
      }

      .query-pagination {
        margin: 50px 0;
      }

      .query-resolve {
        padding-top: 73px;
        font-size: 14px;
        //padding-left: 20px;

        .sdf {
          font-size: 12px;
          color: rgba(114, 113, 113, 1);
        }
      }

      .content-list-empty {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: #999;
      }
    }

    .content-list-right-nav {
      width: 130px;
      box-sizing: border-box;
      padding-top: 45px;


      ul {
        width: 100%;

        li {
          width: 100%;
          padding: 20px 0 10px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 13px;
          font-weight: 400;
          color: #595757;
          line-height: 1.5;
          position: relative;
          border-bottom: solid 1px #e6e6e6;
          cursor: pointer;

          &.active {
            font-weight: 500;
            color: rgba(20, 153, 220, 1);

            &::before {
              width: 100%;
              height: 5px;
              content: "";
              position: absolute;
              left: 0;
              bottom: 0;
              background: rgba(20, 153, 220, 1);
            }
          }

          &:first-child {
            border-top: solid 1px #e6e6e6;
          }
        }
      }

      .content-list-more {
        padding: 20px 0 10px;
        text-align: right;
        font-size: 14px;
        color: rgba(20, 153, 220, 1);
        cursor: pointer;
      }
    }

    .el-divider--horizontal {
      margin: 10px 0 50px;
    }
  }

  .content-list-detail {
    margin-bottom: 50px;
    font-size: 16px;

    img {
      width: initial;
      max-width: 610px;
      height: auto;
    }

    ol, ul, p {
      padding: revert;
      margin: revert;
    }
  }

  .img-list-detail {
    font-size: 16px;

    img {
      width: initial;
      max-width: 610px;
      height: auto;
    }
  }

  .el-button {
    color: rgb(198, 54, 54);
  }
}
</style>